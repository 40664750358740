body {
  margin: 0;
  font-family: "Poppins", sans-serif;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.label {
  font-weight: bold;
}

.clsTitle {
  border: 1px solid #d7d7d7;
  margin: 0px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  background-color: #f8f9fa;
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-body {
  padding: 15px;
  background-color: #fff;
  font-weight: bolder;
  font-size: 0.85rem;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: 600;
  color: #000000;
}

.form-control {
  border-radius: 5px;
  border: 1px solid #ebe7e7;
  padding: 10px;
  transition: border-color 0.3s;
}

::placeholder {
  font-size: 0.875rem; /* Adjust this value as needed */
  color: #888; /* Optional: change the placeholder text color */
  opacity: 1; /* Ensures the color is applied in all browsers */
}

.form-control::placeholder {
  font-size: 0.775rem; /* or whatever size you prefer */
}

.plchldr {
  font-size: 0.875rem; /* Adjust this value as needed */
  color: #888; /* Optional: change the placeholder text color */
  opacity: 1;
}

.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.text-danger {
  font-size: 0.875rem;
  margin-top: 5px;
}

/* .row {
  display: flex;
  flex-wrap: wrap;
} */

/* .col-md-3 {
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 15px;
  padding-left: 15px;
} */

.btn.btn-primary {
  font-size: 14px;
  padding: 9px 12px;
  color: #fff;
  margin-top: 15px;
  /* float: right; */
  margin-left: 10px;
  background-color: #2080b0;
  border: none;
}

/* .btn,
button.btn {
  position: relative;
  overflow: hidden;
} */
